import { Injectable } from '@angular/core';
import { FoodCustomItem } from '@app/app.definitions';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '#env';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FoodCustomItemService {
  apiUrl: string = environment.apiUrl;
  componentApiUrl = this.apiUrl + '/food-custom-item';

  constructor(private http: HttpClient) {
  }

  getCustomItemsForSupplier (supplierId, keyword = null, page=1, limit=999, category?: string, select?: string, sort: string = 'name', order: string = 'asc' ? 'desc' : 'asc'): Observable<any> {
     
    let params = new HttpParams().set('sort', String(sort))
    .set('order', String(order)).set('supplierId', supplierId).set('page', String(page))
    .set('limit', String(limit)).set('category', category);
    
    if(keyword){
      params = params.set('keyword', keyword)
    }
    if(select){
      params = params.set('select', select)
    }
    const categoryUrl = encodeURIComponent(category) ? '/' + encodeURIComponent(category) : '';
    
    return this.http.get(this.apiUrl + '/supplier/' + supplierId + '/food-custom-item' + categoryUrl, { params: params });
  }

  getCustomItemsForChain(chainId, keyword = null, page=1, limit=999, category?: string,sort: string = 'name', order: string = 'asc' ? 'desc' : 'asc'): Observable<any> {
    let params = new HttpParams().set('chainId', chainId).set('page', String(page)).set('limit', String(limit)).set('sort', String(sort))
    .set('order', String(order));
    if(keyword){
      params = params.set('keyword', keyword)
    }
    const categoryUrl = category ? '/' + category : '';
    if (category) {
      params.set('category', category);
    }
    return this.http.get(this.apiUrl + '/chain/' + chainId + '/food-custom-item' + categoryUrl, { params: params });
  }

  getCustomItemsForMultiSupplier(supplierIds: string[]): Observable<any> {
    const params = new HttpParams().set('supplierIds', supplierIds.join(','));
    return this.http.get(this.apiUrl + '/supplier/food-custom-item', { params });
  }

  getCustomItem(id: string, supplierId?: string, type?: string): Observable<any> {
    const urlParams = '/' + id;
    let params = new HttpParams();
    if(type === 'supplier'){
      params = params.set('supplierId', supplierId);
    } else if(type === 'chain'){
      params = params.set('chain', supplierId);
    }
    return this.http.get(this.componentApiUrl + urlParams, {params});
  }

  importAddonsFromSpreadsheet(spreadsheetId: string): Observable<any> {
    const params = new HttpParams().set('spreadsheetId', spreadsheetId);
    return this.http.get(this.componentApiUrl + '/import', { params });
  }

  saveCustomItem(item: FoodCustomItem, type: 'supplier' | 'chain'): Observable<any> {
    const subcomponentApiUrl = this.apiUrl + '/' + type + '/' + (type === 'chain' ? item.chainId : item.supplierId) + '/food-custom-item';

    let urlParams = '';
    if (item._id) {
      urlParams = '/' + item._id;
    }
    return this.http.post(subcomponentApiUrl + urlParams, item);
  }

  deleteCustomItem(id: string,bulkIds=[]): Observable<any> {
    if (!id) {
      return EMPTY;
    }

    return this.http.delete(this.componentApiUrl + '/' + id,{body:{bulkIds:bulkIds}});
  }

  deleteCustomImage(id: string, filename: string,type: string): Observable<any> {
    const urlParams = '/delete-image/' + id + '/' + filename+'/'+type;
    return this.http.delete(this.componentApiUrl + urlParams);
  }

  uploadCustomImage(foodCustomItemId: string, data: any,type: string): Observable<any> {
    const urlParams = '/upload-image/' + foodCustomItemId+'/'+type;
    return this.http.post(this.componentApiUrl + urlParams, data);
  }

  updateSupplierStock(stock, supplierId) {
    return this.http.post(`${this.apiUrl}/food-custom-item-stock/${supplierId}`, stock);
  }
}
