import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';
import {RequestsService} from '@app/services/requests.service';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {IResponse, IRestaurantLoginResponse, Response} from '#defs';
import {ActivatedRoute} from '@angular/router';
import {SettingsService} from '@app/services/settings.service';
// for first time login menu populate correctly
import {MenuItems} from '@app/shared/menu-items/menu-items';
import ApplicationMenuItems from '@app/app.menu';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loading = false;
  enableOtpField = false;
  private useAsRestaurant = false;
  public loginForm: UntypedFormGroup;
  public error: any;
  public Response: IResponse<any> = null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private requests: RequestsService,
              private settingsService: SettingsService,
              private auth: AuthenticationService) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data && data.useAsRestaurant) {
        this.useAsRestaurant = data.useAsRestaurant;
      }
    });
    this.route.queryParams.subscribe(data => {
      if (data && data.error) {
        this.error = data.error;
      }
    });
    this.authenticationService.logout();
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, Validators.required),
      otpCode: new UntypedFormControl(null),
      stayLogged: new UntypedFormControl(false)
    });
  }

  public FormError(fieldName: string) {
    if (!this.Response || !this.Response.error || !this.Response.error.errors) {
      return '';
    }
    const error = this.Response.error.errors.find(x => x.location === fieldName);
    return error ? error.message : '';
  }

  public onSubmit() {
    this.loading = true;
    this.error = null;

    const username = this.loginForm.value.username;
    const otpCode = this.loginForm.value.otpCode;
    const password = this.loginForm.value.password;
    const stayLogged = this.loginForm.value.stayLogged;

    if (this.useAsRestaurant) {
      return this.ProcessLoginRestaurant(username, password, stayLogged);
    }
    this.ProcessLoginAdministrator(username, password, stayLogged,otpCode);
  }

  private ProcessLoginRestaurant(username: string, password: string, stayLogged: boolean) {
    this.requests.loginRestaurant(username, password, stayLogged)
      .subscribe((data: IRestaurantLoginResponse) => {
          this.authenticationService.SetUser(data.supplier as any);
          this.authenticationService.SetCapabilities([]);
          this.authenticationService.SetToken(data.token);
          this.router.navigateByUrl('/order-summary');
          this.settingsService.getSettings();
        },
        (err: any) => {
          this.error = err.message ? err.message : 'Error, please try again';
          this.loading = false;
        });
  }

  // supplier-login
  private ProcessLoginAdministrator(username: string, password: string, stayLogged: boolean,otpCode:string) {
    this.requests.loginUser(username, password, stayLogged,otpCode).subscribe((response: Response) => {
        if (!response.success) {
          if(response.data.otpCodeRequired){
            this.enableOtpField=true;
          }
          this.error = response.error.description;
          return;
        }

        const {capabilities, user, token, setting} = response.data;
        this.authenticationService.SetCapabilities(capabilities);
        this.authenticationService.SetUser({...user, setting});
        this.authenticationService.SetToken(token);     
        MenuItems.SetMenuItems(ApplicationMenuItems);
        this.router.navigateByUrl(this.auth.getHomePage());
        this.settingsService.getSettings();
      },
      (err) => {
        if(err && err.error && err.error.data && err.error.data.otpCodeRequired){
          this.enableOtpField=true;
        }
        this.error = err?.error?.error?.description || 'Error, please try again';
        this.loading = false;
      });
  }
}
