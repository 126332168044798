import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule, Injectable} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {AppRoutingModule} from '@app/app.routes';
import {AppComponent} from '@app/app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '#env';
import {MenuItems} from '@app/shared/menu-items/menu-items';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
//import {NgxMdModule} from 'ngx-md';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatNativeDateModule, NativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTreeModule} from '@angular/material/tree';
import {AuthGuard} from '@app/guards/auth.guard';
import ApplicationMenuItems from '@app/app.menu';
import ApplicationCapabilities from '@app/app.capabilities';
import {CurrencyPipe} from '@angular/common';
//import {AgmCoreModule} from '@agm/core';
import {DialogTopupFormComponent} from '@app/dialogs/topup-form/topup-form.component';
import {DialogPxlFormComponent} from '@app/dialogs/plx-form/pxl-form.component';
import {SmsMessageFormComponent} from '@app/dialogs/sms-message-form/sms-message-form.component';
import {BuildingDialogComponent} from '@app/components/building/building-dialog/building-dialog.component';
import {DialogCustomerFormComponent} from '@app/dialogs/customer-form/customer-form.component';
import {DialogPrepareForSMSComponent} from '@app/dialogs/prepare-for-sms/prepare-for-sms.component';
import {TokenInterceptor} from '@app/services/requests.service';
import {DialogDeliveryFormDialogComponent} from '@app/dialogs/delivery-form/delivery-form.component';
import {InquiryDetailsComponent} from '@app/dialogs/inquiry-details/inquiry-details.component';
import {DialogDeliveryAssignmentFormDialogComponent} from '@app/dialogs/delivery-assignment-form/delivery-assignment-form.component';
import {SendSMSDialogComponent} from '@app/dialogs/send-sms/send-sms.component';
import {AclService} from '@app/services/acl.service';
import {AccessDeniedComponent} from '@app/components/access-denied/access-denied.component';
import {SettingsService} from '@app/services/settings.service';
import {FoodItemIngredientsDialogComponent} from '@app/components/supplier/supplier-view/ingredients-modal/ingredients-modal.component';
import {InvoiceActionModalComponent} from '@app/components/finance/vendor/invoice-action-modal/invoice-action-modal.component';
import {CorporateInvoiceActionModalComponent} from '@app/components/finance/corporate/corporate-invoice-action-modal/corporate-invoice-action-modal.component';
import {RefundActionModalComponent} from '@app/components/finance/refund/refund-action-modal/refund-action-modal.component';

import {
  FoodCustomItemPanelComponent,
  FoodItemStockPanelComponent
} from '@app/components/supplier/supplier-view/supplier-item-view/supplier-item-view.component';
import {DailyMenuBulkAddComponent} from './components/daily-menu/daily-menu-view/daily-menu-bulk-add/daily-menu-bulk-add.component';
import {FaqDialogComponent} from '@app/components/faq/faq-dialog/faq-dialog.component';
import {CreateNewZoneModalComponent} from '@app/components/zone/new-zone-modal/new-zone.component';
import {TermDialogComponent} from './components/term/term-dialog/term-dialog.component';
import {DialogNuggetFormComponent} from '@app/dialogs/nugget-form/nugget-form.component';
import {IngredientsModalComponent} from './components/modals/ingredients-modal/ingredients-modal.component';
import {CorporateAdminComponent} from '@app/components/corporate-admin/corporate-admin.component';
import {CorporateAdminFormComponent} from '@app/components/corporate-admin/corporate-admin-form/corporate-admin-form.component';
import {CorporateLocationFormComponent} from '@app/components/corporate/corporate-form/corporate-location-form/corporate-location-form.component';
import {CorporateAllowanceFormComponent} from '@app/components/corporate/corporate-form/corporate-allowance-form/corporate-allowance-form.component';
import {PromoViewModalComponent} from '@app/components/promo/promo-view-modal/promo-view-modal.component';
import {UpcomingOrdersComponent} from '@app/components/upcoming-orders/upcoming-orders.component';
import {DialogInvoiceNumberComponent} from '@app/dialogs/dialog-invoice-number/dialog-invoice-number.component';
import {AddonsModalComponent} from './components/directory/directory.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgxIntlTelInputModule} from '@app/components/common/ngx-intl-tel-input';
import {OAuthModule} from 'angular-oauth2-oidc';
import {NgxEchartsModule} from 'ngx-echarts';
import {AddInquiryDetailsComponent} from '@app/dialogs/new-inquiry/add-inquiry-details.component';
import {ImportItemDetailModalComponent} from '@app/components/supplier/supplier-view/supplier-import/import-item-detail-modal/import-item-detail-modal.component';
import {SbCommonModule} from '@app/modules/sb-common.module';
import {VendorAccountModalComponent} from '@app/components/supplier/chains/chains-form/vendor-account-modal/vendor-account-modal.component';
import {OrderReviewModalComponent} from '@app/components/supplier/supplier-details/order-review-modal/order-review-modal.component';
import {SmartcanteenDateModalComponent} from '@app/components/supplier/supplier-details/smartcanteen-date-modal/smartcanteen-date-modal.component';
import {MarketPlaceDateModalComponent} from '@app/components/supplier/supplier-details/marketplace-date-modal/marketplace-date-modal.component';
import {TemporaryCloseTimeConfirmationComponent} from '@app/components/supplier/supplier-details/confirmation-modal/temporary-close-time-confirmation.component';
import {DialogPendingRequestComponent} from './dialogs/dialog-pending-request/dialog-pending-request.component';
import {OrderHistoryDialogComponent} from './dialogs/order-history-dialog/order-history-dialog.component';
import {CreateRefundDialogComponent} from './dialogs/create-refund-dialog/create-refund-dialog.component';
import * as Sentry from '@sentry/angular';
import {Integrations} from '@sentry/tracing';
import { DialogStoreStockComponent } from './dialogs/dialog-store-stock/dialog-store-stock.component';
import { CorporateGiftsOrdersComponent } from './components/corporate/corporate-form/corporate-gifts-orders/corporate-gifts-orders.component';
import { DialogImageViewComponent } from './dialogs/dialog-image-view/dialog-image-view.component';
import { DialogCorporateGiftExportComponent } from './dialogs/dialog-corporate-gift-export/dialog-corporate-gift-export.component';
import { RefundModalComponent } from './components/modals/refund-modal/refund-modal.component';
import { DialogCorporateEmployeeComponent } from './dialogs/dialog-corporate-employee/dialog-corporate-employee.component';
import { CorporateSmartCanteenTopUpFormComponent } from './components/corporate/corporate-form/corporate-smart-canteen-top-up-form/corporate-smart-canteen-top-up-form.component';
import { SmartCanteenTopupComponent } from './components/finance/smart-canteen-topup/smart-canteen-topup.component';
import { DialogSupplierImportCsvComponent } from './dialogs/dialog-supplier-import-csv/dialog-supplier-import-csv.component';
import { DialogJobStateComponent } from './dialogs/dialog-job-state/dialog-job-state.component';
import { DialogDeliveryBaseFeeComponent } from './dialogs/dialog-delivery-base-fee/dialog-delivery-base-fee.component';
import { ClosingDateModalComponent } from './components/supplier/supplier-details/closing-date-modal/closing-date-modal.component';
import { FeedbackResponseModalComponent } from './dialogs/feedback-response-modal/feedback-response-modal.component';
import { CustomizationComponent } from './components/supplier/supplier-imports/customization/customization.component';
import { DeliveryTrackingModalComponent } from './components/orders/delivery-tracking-modal/delivery-tracking-modal.component';
import { LogisticsSettingsComponent } from './components/operations/logistics-settings/logistics-settings.component';
import { CorporateLocationChangeComponent } from './components/corporate/corporate-form/corporate-location-change/corporate-location-change.component';
import { RefundComponent } from './components/operations/refund/refund.component';
import { DialogConciergeFormComponent } from './dialogs/concierge-form/concierge-form.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RefundRejectionModalComponent } from './components/modals/refund-rejection-modal/refund-rejection-modal.component';
import { PenaltiesComponent } from './components/operations/penalties/penalties.component';
import { PenaltiesRejectionModalComponent } from './components/modals/penatlies-rejection-modal/penalties-rejection-modal.component';

AclService.SetItems(ApplicationCapabilities);
MenuItems.SetMenuItems(ApplicationMenuItems);

@Injectable({providedIn: 'root'})
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (environment.sentry.enabled) {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  }
}

export function initApp(settingsService: SettingsService) {
  return async () => {
    if (localStorage.getItem('userToken')) {
      await settingsService.getSettings();
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DialogDeliveryFormDialogComponent,
    InquiryDetailsComponent,
    DialogDeliveryAssignmentFormDialogComponent,
    BuildingDialogComponent,
    DialogPrepareForSMSComponent,
    DialogCustomerFormComponent,
    AddInquiryDetailsComponent,
    DialogTopupFormComponent,
    DialogPxlFormComponent,
    SmsMessageFormComponent,
    SendSMSDialogComponent,
    FoodItemIngredientsDialogComponent,
    InvoiceActionModalComponent,
    CorporateInvoiceActionModalComponent,
    RefundActionModalComponent,
    DailyMenuBulkAddComponent,
    FaqDialogComponent,
    CreateNewZoneModalComponent,
    TermDialogComponent,
    DialogNuggetFormComponent,
    FoodCustomItemPanelComponent,
    FoodItemStockPanelComponent,
    IngredientsModalComponent,
    AddonsModalComponent,
    CorporateAdminComponent,
    CorporateAdminFormComponent,
    CorporateLocationFormComponent,
    CorporateLocationChangeComponent,
    CorporateAllowanceFormComponent,
    DialogInvoiceNumberComponent,
    PromoViewModalComponent,
    AccessDeniedComponent,
    UpcomingOrdersComponent,
    ImportItemDetailModalComponent,
    VendorAccountModalComponent,
    OrderReviewModalComponent,
    FeedbackResponseModalComponent,
    SmartcanteenDateModalComponent,
    MarketPlaceDateModalComponent,
    TemporaryCloseTimeConfirmationComponent,
    DialogPendingRequestComponent,
    OrderHistoryDialogComponent,
    CreateRefundDialogComponent,
    RefundModalComponent,
    RefundRejectionModalComponent,
    PenaltiesRejectionModalComponent,
    DialogConciergeFormComponent,
    DialogStoreStockComponent,
    CorporateSmartCanteenTopUpFormComponent,
    SmartCanteenTopupComponent,
    CorporateGiftsOrdersComponent,
    DialogImageViewComponent,
    DialogCorporateGiftExportComponent,
    DialogCorporateEmployeeComponent,
    CorporateSmartCanteenTopUpFormComponent,
    SmartCanteenTopupComponent,
    DialogSupplierImportCsvComponent,
    DialogJobStateComponent,
    DialogDeliveryBaseFeeComponent,
    ClosingDateModalComponent,
    CustomizationComponent,
    DeliveryTrackingModalComponent,
    LogisticsSettingsComponent,
    RefundComponent,
    PenaltiesComponent,
  ],
  imports: [
    SbCommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatTreeModule,
    FormsModule,
    MatNativeDateModule,
    HttpClientModule,
    DragDropModule,
    AppRoutingModule,
    BrowserModule,
    MatMomentDateModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDOJaCLIqXOIFZJ2iLKQXby-xT13UzfUS4'
    // }),
    MatSnackBarModule,
    MatToolbarModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDialogModule,
    MatListModule,
    MatPaginatorModule,
    MatTabsModule,
    NativeDateModule,
    MatRadioModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    FormsModule,
    NgxIntlTelInputModule,
    //NgxMdModule.forRoot(),
    OAuthModule.forRoot(),
    NgxEchartsModule
  ],
  providers: [
    MenuItems,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [SettingsService, Sentry.TraceService]
    },
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
  exports: [SbCommonModule]
})
export class AppModule {
}
